import { Api } from "@/models/class/api.class";
import { IResponseListGoodReceipt, RequestReceivingItemCreate, RequestReceivingItemUpdate, ResponseReceivingItem, ResponseReceivingItemCancel, ResponseReceivingItemDraft } from "@/models/interface-v2/receiving-item.interface";
import { ErrorResponseModel, RequestQueryParamsModel } from "@/models/interface/http.interface";
import { HttpClient } from "@/services/http.service";
import { AxiosError } from "axios";

export class ReceiveItemsService extends HttpClient {
  public constructor() {
    super();

    this.downloadReportReceivingItems = this.downloadReportReceivingItems.bind(this);
    this.listOfReceivingItems = this.listOfReceivingItems.bind(this);
    this.getReceiveItemsDraft = this.getReceiveItemsDraft.bind(this);
    this.createReceivingItems = this.createReceivingItems.bind(this);
    this.updatebyIdReceivingItems = this.updatebyIdReceivingItems.bind(this);
    this.createReceivingItemsDraft = this.createReceivingItemsDraft.bind(this);
  }

  downloadReportReceivingItems(params: RequestQueryParamsModel) :Promise<ArrayBuffer> {
    return this.get<ArrayBuffer>(Api.ReceiveItemDownload , { params,  responseType: "arraybuffer" })
    .then(this.success)
    .catch((error: AxiosError<ErrorResponseModel>) => {
      throw this.error(error);
    });
  }

  public createReceivingItemsDraft(payload: RequestReceivingItemCreate): Promise<boolean> {
    return this.post<boolean, RequestReceivingItemCreate>(Api.ReceiveItemDraft , payload)
    .then(this.success)
    .catch((error: AxiosError<ErrorResponseModel>) => {
      throw this.error(error);
    });
  }

  public getReceiveItemsDraft(path: string): Promise<ResponseReceivingItemDraft> {
    return this.get<ResponseReceivingItemDraft>(Api.ReceiveItemDraft + `/${path}`)
    .then(this.success)
    .catch((error: AxiosError<ErrorResponseModel>) => {
      throw this.error(error);
    });
  }

  /**
   * get good receipt for purchasing team
   * @param params query params
   * @returns list GR
   */
   public listOfReceivingItems(params : RequestQueryParamsModel): Promise<IResponseListGoodReceipt> {
    return this.get<IResponseListGoodReceipt>(Api.ReceiveItem , { params })
    .then(this.success)
    .catch((error: AxiosError<ErrorResponseModel>) => {
      throw this.error(error);
    });
  }

  /**
   * get detail receiving item by id
   * @param id receiving item id
   * @returns response receiving item
   */
  public getDetailReceivingItem(id: string): Promise<ResponseReceivingItem> {
    return this.get<ResponseReceivingItem>(`${Api.ReceiveItem}/${id}`)
    .then(this.success)
    .catch((error: AxiosError<ErrorResponseModel>) => {
      throw this.error(error);
    });
  }

  public createReceivingItems(payload: RequestReceivingItemCreate): Promise<ResponseReceivingItem> {
    return this.post<ResponseReceivingItem, RequestReceivingItemCreate>(Api.ReceiveItem , payload)
    .then(this.success)
    .catch((error: AxiosError<ErrorResponseModel>) => {
      throw this.error(error);
    });
  }

  public updatebyIdReceivingItems(payload: RequestReceivingItemUpdate, id : string): Promise<ResponseReceivingItem> {
    return this.put<ResponseReceivingItem, RequestReceivingItemUpdate>(Api.ReceiveItem + `/${id}`, payload)
    .then(this.success)
    .catch((error: AxiosError<ErrorResponseModel>) => {
      throw this.error(error);
    });
  }

  submitReceiveItem(id: string, payload: RequestReceivingItemUpdate): Promise<boolean> {
    return this.post<boolean, RequestReceivingItemUpdate>(`${Api.ReceiveItem}/submit/${id}`, payload)
    .then(this.success)
    .catch((error: AxiosError<ErrorResponseModel>) => {
      throw this.error(error);
    });
  }

  /**
   * cancel good receipt
   * @param id receiving item secureId
   * @returns response receiving item
   */
  cancelReceiveItem(id: string): Promise<ResponseReceivingItemCancel> {
    return this.put<ResponseReceivingItemCancel, null>(`${Api.ReceiveItem}/cancel/${id}`)
    .then(this.success)
    .catch((error: AxiosError<ErrorResponseModel>) => {
      throw this.error(error);
    });
  }

  approveReceiveItem(id: string, payload: RequestReceivingItemUpdate): Promise<boolean> {
    return this.post<boolean, RequestReceivingItemUpdate>(`${Api.ReceiveItem}/approve/${id}`, payload)
    .then(this.success)
    .catch((error: AxiosError<ErrorResponseModel>) => {
      throw this.error(error);
    });
  }

}

export const receiveItemsService = new ReceiveItemsService();