import Decimal from "decimal.js-light";

/**
 * find tax amount based on INCLUSIVE
 * calculation
 * @param taxRate rate of tax code
 * @param baseAmount base amount of calculation 
 * @returns amount of calculated tax
 */
export const findInclusive =
  (
    { taxRate, baseAmount }:
    { taxRate: number, baseAmount: number, }
  ):
  {
    amount: number,
  } => {
    let amount = new Decimal(0);
    const rate = new Decimal(taxRate || 0).dividedBy(100);
    amount = new Decimal(baseAmount).minus(new Decimal(baseAmount || 0).dividedBy(rate.plus(1)));
    return {
      amount: amount.toNumber(),
    };
};

/**
 * find tax amount based on EXCLUSIVE
 * calculation
 * @param taxRate rate of tax code
 * @param baseAmount base amount of calculation 
 * @returns amount of calculated tax
 */
export const findExclusive =
  (
    { taxRate, baseAmount }:
    { taxRate: number, baseAmount: number, }
  ): {
    amount: number,
  } => {
    let amount = new Decimal(0);
    const rate = new Decimal(taxRate || 0).dividedBy(100);
    amount = new Decimal(baseAmount || 0).times(rate);
    return {
      amount: amount.toNumber(),
    };
  };
