










































































































































































































































































































































































































































































import Vue from "vue";
import { receiveItemsService } from "@service/receive-item.service";
import { RequestReceivingItemUpdate, ResponseReceivingItem } from "@interface/receiving-item.interface";
import moment from "moment";
import { DEFAULT_DATE_FORMAT } from "@constant/date.constant";
import { formatterNumber, reverseFormatNumber, formatCurrency, validateTable } from "@/validator/globalvalidator";
import { GR_LOCAL_STORAGE, GR_STATUS } from "@enum/good-receipt.enum";
import MNotificationVue from "@/mixins/MNotification.vue";
import { contactServices } from "@service/contact.service";
import { ResponseAccountingCurrency } from "@interface/currency.interface";
import { ResponseAccountingTax } from "@interface/accounting-tax.interface";
import { accountingCurrencyConversionService } from "@service/accounting-currency-conversion.service";
import { Decimal } from "decimal.js-light";
import { TAX_CALCULATION, TAX_TYPE } from "@/models/enums/tax.enum";
import { DECIMAL_PLACES_QTY, DECIMAL_PLACES_CURRENCY } from "@/models/constant/global.constant";
import { FormModel } from "ant-design-vue";
import { findExclusive, findInclusive } from "@helper/tax-calculator";
import { mapActions, mapGetters, mapMutations } from "vuex";
import localStorageService from "@/services/localStorage.service";
import { APP_DECIMAL_PLACES, Mode } from "@/models/enums/global.enum";

interface Row {
  no: number;
  key: string;
  productCode: string;
  productName: string;
  productId: string;
  productUomId: string;
  qtyPo: number;
  productUom: string;
  qtyReceived: number;
  unitPrice: number;
  grossAmount: number;
  discount: {
    percent: number,
    value: number
  };
  total: number;
  locationReceived: string;
  locationReceivedId: string;
  trackAsAsset: boolean;
  batchNumber: string;
  batchNumberId: string;
  condition: string;
  baseAmount: number;
  taxAmount: number;
  taxRate: number;
  taxId: string | undefined | null;
  receivingId: string;
}

export default Vue.extend({
  name: "GoodReceiptPurchase",
  components: {
    CSelectTermOfPayment: () => import(/*webpackPrefetch: true*/"@/components/shared/select-term-of-payment/CSelectTermOfPayment.vue"),
    CSelectCurrency: () => import(/*webpackPrefetch: true*/"@/components/shared/select-currency/CSelectCurrency.vue"),
    CSelectTaxCode: () => import(/*webpackPrefetch: true */"@/components/shared/select-tax-code/CSelectTaxCode.vue"),
    CSelectTaxCalculation: () => import(/*webpackPrefetch: true */"@/components/shared/select-tax-calculation/CSelectTaxCalculation.vue"),
  },
  mixins: [
    MNotificationVue,
  ],
  data() {
    return {
      TAX_TYPE,
      DEFAULT_DATE_FORMAT,
      DECIMAL_PLACES_QTY,
      DECIMAL_PLACES_CURRENCY,
      formModel: {
        supplierName: "",
        supplierId: "",
        poNumber: "",
        receiveDate: "",
        grChecklistNumber: "",
        top: undefined  as string | undefined,
        supplierDoNumber: "",
        supplierBillTo: "",
        supplierShipToAddress: "",
        description: "",
        status: "",
        productLines: [] as Row[],
        branchName: "",
        branchId: "",
        currency: "",
        currencyId: undefined  as string | undefined,
        currencyRates: undefined  as number | undefined,
        supplierImport: false,
        taxCalculation: TAX_CALCULATION.NONE as TAX_CALCULATION ,
        grChecklistId: null as string | null,
      },
      dtDetailReceivingItem: {} as ResponseReceivingItem,
      loading: {
        detail: false,
        submit: false,
        loading: false,
        approve: false,
      },
      goodReceiptId: "",
      rules: {
        top: [{ required: true, trigger: "change", message: () => this.$t("lbl_validation_required_error")}],
        currencyId: [{ required: true, trigger: "change", message: () => this.$t("lbl_validation_required_error")}],
        currencyRates: [{ required: true, trigger: "change", message: () => this.$t("lbl_validation_required_error")}]
      },
    };
  },
  computed: {
    ...mapGetters({
      userPrivileges: "authStore/GET_USER_PRIVILEGES"
    }),
    formWrapper() {
      return {
        labelCol: {
          sm: {
            span: 24
          },
          md: {
            span: 7
          }
        },
        wrapperCol: {
          sm: {
            span: 24
          },
          md: {
            span: 17
          }
        }
      };
    },
    totalGrossAmount(): number {
      return this.formModel.productLines.reduce((x, y) => new Decimal(y.grossAmount || 0).plus(x).toNumber(), 0);
    },
    totalDiscount(): number {
      return this.formModel.productLines.reduce((x, y) => new Decimal(y.discount.value || 0).plus(x).toNumber(), 0);
    },
    grandTotal(): number {
      return new Decimal(this.totalGrossAmount).minus(this.totalDiscount || 0).plus(this.totalTax).toNumber();
    },
    totalTax(): number {
      return this.formModel.productLines.reduce((x, y) => new Decimal(y.taxAmount || 0).plus(x).toNumber(), 0);
    },
    isUnbilled(): boolean {
      return this.dtDetailReceivingItem.status === GR_STATUS.UNBILLED;
    },
    isSubmitted(): boolean {
      return this.dtDetailReceivingItem.status === GR_STATUS.SUBMITTED;
    },
    isWaitForApproval(): boolean {
      return this.dtDetailReceivingItem.status === GR_STATUS.WAIT_FOR_APPROVAL;
    },
    totalQtyReceived(): number {
      return this.formModel.productLines.reduce((x, y) => new Decimal(y.qtyReceived || 0).plus(x).toNumber(), 0);
    },
    totalPrice(): number {
      return this.formModel.productLines.reduce((x, y) => new Decimal(y.unitPrice || 0).plus(x).toNumber(), 0);
    },
    isTaxCalculationNone(): boolean {
      return this.formModel.taxCalculation === TAX_CALCULATION.NONE;
    },
    isTaxCalculationExclusive(): boolean {
      return this.formModel.taxCalculation === TAX_CALCULATION.EXCLUSIVE;
    },
    isTaxCalculationInclusive(): boolean {
      return this.formModel.taxCalculation === TAX_CALCULATION.INCLUSIVE;
    },
    hasPrivilegeApprove(): boolean {
      return !!this.userPrivileges.find(x => x.key === "approval-inventory-receive");
    },
    hasPrivilegeCreate(): boolean {
      return !!this.userPrivileges.find(x => x.key === "inventory-receive" && x.privilege.create);
    },
    hasPrivilegeUpdate(): boolean {
      return !!this.userPrivileges.find(x => x.key === "inventory-receive" && x.privilege.update);
    },
    isModeEdit(): boolean {
      return this.$route.meta.mode === Mode.EDIT;
    },
  },
  created() {
    this.goodReceiptId = this.$route.params.id || "";
    const cache = sessionStorage.getItem(GR_LOCAL_STORAGE.GR_PURCHASE_FORM);
    if (cache) {
      this.loadFormCache();
    } else {
      this.getDetailReceivingItem(this.goodReceiptId);
    }
  },
  methods: {
    formatterNumber,
    reverseFormatNumber,
    formatCurrency,
    moment,
    onSelectTaxCalculation(): void {
      this.formModel.productLines.forEach(x => {
        x.taxId = undefined;
        x.taxAmount = 0;
        x.taxRate = 0;
      });
    },
    ...mapMutations({
      setGoodReceiptDetail: "importCostStore/SET_GOOD_RECEIPT_DETAIL",
      setGoodReceiptPurchaseForm: "importCostStore/SET_GOOD_RECEIPT_PURCHASE_FORM",
    }),
    ...mapActions({
      resetStoreImportCost: "importCostStore/ACT_RESET_STATE",
    }),
    async getDetailReceivingItem(id: string): Promise<void> {
      try {
        if (!id) return;
        const dp = localStorageService.load(APP_DECIMAL_PLACES.DP) || DECIMAL_PLACES_CURRENCY;
        this.loading.detail = true;
        const res = await receiveItemsService.getDetailReceivingItem(id);
        const { top } = await contactServices.getContactData(res.supplierId);
        this.dtDetailReceivingItem = res;
        this.formModel = {
          taxCalculation: res.taxCalculation || TAX_CALCULATION.NONE,
          supplierName: res.supplierName,
          supplierId: res.supplierId,
          poNumber: res.purchaseOrderNumber,
          receiveDate: res.receiveDate,
          grChecklistNumber: res.checklistNumber,
          supplierDoNumber: res.supplierDeliveryOrderNo,
          top: res.top !== null ? res.top.toString() : (top >= 0 ? (top?.toString() || undefined) : undefined ),
          supplierBillTo: res.supplierBillToAddress,
          supplierShipToAddress: res.supplierShipToAddress,
          description: res.description,
          status: res.status,
          productLines: [],
          branchName: res.branchName,
          branchId: res.branchId,
          currency: res.currencyCode || "",
          currencyId: res.currencyId || undefined,
          currencyRates: res.rateCurrency,
          supplierImport: res.supplierImport,
          grChecklistId: res.id,
        };
        if (res.itemListResponseDTOS) {
          res.itemListResponseDTOS.forEach((item, i) => {
            this.formModel.productLines.push({
              no: i + 1,
              key: item.id,
              productCode: item.productCode,
              productName: item.productName,
              productId: item.productId,
              productUomId: item.productUomId,
              qtyPo: item.qtyPO,
              productUom: item.productUom,
              qtyReceived: item.productQty,
              unitPrice: item.productPrice || 0,
              grossAmount: new Decimal(item.productPrice || 0).times(item.productQty).toDecimalPlaces(+dp, Decimal.ROUND_HALF_EVEN).toNumber(),
              discount: {
                percent: item.discount || 0,
                value: item.discount || 0
              },
              total: item.total || 0,
              locationReceived: item.locationReceived,
              locationReceivedId: item.locationReceivedId,
              trackAsAsset: item.trackAsAsset,
              batchNumber: item.batchNumber,
              batchNumberId: item.batchNumberId,
              condition: item.qualityControl?.condition || "",
              baseAmount: item.baseAmount || new Decimal(item.productPrice || 0).times(item.productQty || 0).minus(item.discount || 0).toDecimalPlaces(+dp, Decimal.ROUND_HALF_EVEN).toNumber(),
              taxAmount: item.taxAmount || 0,
              taxRate: item.rateTax || 0,
              taxId: item.taxId || undefined,
              receivingId: item.receivingId,
            });
          });
        }

        /**
         * load form cache
         */
        // this.loadFormCache();

      } catch (error) {
        this.showErrorMessage("notif_process_fail");
      } finally {
        this.loading.detail = false;
      }
    },
    /**
     * load form cache from local storage
     */
    loadFormCache(): void {
      const cache = sessionStorage.getItem(GR_LOCAL_STORAGE.GR_PURCHASE_FORM);
      if (!cache) return;
      const form = JSON.parse(cache);
      this.formModel = form;
    },
    calcTaxAmount(record: Row): void {
      if (this.isTaxCalculationExclusive) {
        const param = {
          taxRate: record.taxRate,
          baseAmount: record.baseAmount,
        };
        record.taxAmount = findExclusive(param).amount || 0;
      } else if (this.isTaxCalculationInclusive) {
        const param = {
          taxRate: record.taxRate,
          baseAmount: record.grossAmount,
        };
        record.taxAmount = findInclusive(param).amount || 0;
      } else {
        record.taxAmount = 0;
      }
    },
    calcTotal(record: Row): void {
      if (this.isTaxCalculationExclusive) {
        record.total = new Decimal(record.baseAmount || 0).plus(record.taxAmount).toNumber();
      } else if (this.isTaxCalculationInclusive) {
        record.total = record.baseAmount;
      } else {
        record.total = record.baseAmount;
      }
    },
    handleBack(): void {
      this.resetStoreImportCost();
      this.$router.push({ name: "logistic.receivingitems.goodreceipt" });
    },
    createPayloadUpdate(): RequestReceivingItemUpdate {
      const { formModel, dtDetailReceivingItem } = this;
      const payload: RequestReceivingItemUpdate = {
        branchId: formModel.branchId,
        currencyId: formModel.currencyId || "",
        deletedReceiveItemIds: [],
        description: formModel.description,
        goodsReceiptChecklistId: this.goodReceiptId || "",
        purchaseOrderNumber: formModel.poNumber,
        rateCurrency: formModel.currencyRates || 1,
        receiveDate: formModel.receiveDate,
        receiveItems: [],
        supplierDeliveryOrderNo: formModel.supplierDoNumber,
        supplierBillToAddress: formModel.supplierBillTo,
        supplierId: dtDetailReceivingItem.supplierId,
        supplierShipToAddress: dtDetailReceivingItem.supplierShipToAddress,
        top: formModel.top ? +formModel.top : 0,
        supplierImport: formModel.supplierImport || false,
        taxCalculation: formModel.taxCalculation || null,
      };
      formModel.productLines.forEach(item => {
        payload.receiveItems.push({
          discount: item.discount.value,
          locationReceivedId: item.locationReceivedId,
          price: item.unitPrice,
          productId: item.productId,
          productUomId: item.productUomId,
          qty: item.qtyReceived,
          secureId: item.key,
          trackAsAsset: item.trackAsAsset,
          batchId: item.batchNumberId,
          qualityControl: { condition: item.condition },
          rateTax: item.taxRate,
          taxId: item.taxId || null,
          taxAmount: item.taxAmount,
          baseAmount: item.baseAmount,
          total: item.total,
          qtyPO: item.qtyPo,
        });
      });
      return payload;
    },
    validateForm(): void {
      const keys = [
        {
          key: "unitPrice",
          message: "lbl_unit_price",
        }
      ];
      const {colValid, messages} = validateTable(this.formModel.productLines, keys);
      const form = this.$refs.grform as FormModel;
      form.validate((valid: boolean) => {
        if (valid && colValid) {
          if (this.isSubmitted) {
            this.handleSubmit();
          } else if (this.isWaitForApproval) {
            this.handleUpdate();
          }
        } else {
          const desc = `${this.$t(messages[0] || "")}. ${this.$t("lbl_at_row", {row: messages[1]})}`;
          this.showNotifValidationError(desc);
        }
      });
    },
    handleUpdate(): void {
      const payload = this.createPayloadUpdate();
      this.loading.submit = true;
      receiveItemsService.updatebyIdReceivingItems(payload, this.goodReceiptId)
      .then(() => {
        this.showSuccessMessage("notif_update_success");
        this.getDetailReceivingItem(this.goodReceiptId);
        this.resetStoreImportCost();
      })
      .catch(() => this.showErrorMessage("notif_update_fail"))
      .finally(() => this.loading.submit = false);
    },
    handleSubmit(): void {
      const payload = this.createPayloadUpdate();
      this.loading.submit = true;
      receiveItemsService.submitReceiveItem(this.goodReceiptId, payload)
      .then(() => {
        this.showSubmitSuccessMesssage();
        this.getDetailReceivingItem(this.goodReceiptId);
        this.resetStoreImportCost();
      })
      .catch(() => {
        this.showSubmitFailedMesssage();
      })
      .finally(() => this.loading.submit = false);
    },
    async onSelectCurrency({meta}: {meta: ResponseAccountingCurrency}): Promise<void> {
      this.formModel.currency = meta.currencyCode;
      this.formModel.currencyRates = undefined;
      if (meta.currencyCode !== "IDR") {
        this.findCurrencyRates(this.formModel.currencyId);
      }
    },
    onSelectTax({ meta }: {meta: ResponseAccountingTax}, record: Row): void {
      record.taxRate = meta.rate;
      this.calcTaxAmount(record);
      this.calcTotal(record);
    },
    async findCurrencyRates(baseCurrency: string | undefined): Promise<void> {
      try {
        if (!baseCurrency) return;
        const search = `fromCurrency.secureId~${baseCurrency}_AND_toCurrency.currencyCode~IDR`;
        const { data } = await accountingCurrencyConversionService.conversionList({ search });
        if (!data.length) {
          this.showNotifWarning("notif_currency_rate_not_found");
          return;
        }
        this.formModel.currencyRates = data[0].rate;
      } catch (error) {
        this.showErrorMessage("notif_process_fail");
      }
    },
    showImportCost(): void {
      this.setGoodReceiptDetail(this.dtDetailReceivingItem);
      this.setGoodReceiptPurchaseForm(this.formModel);
      sessionStorage.setItem(GR_LOCAL_STORAGE.GR_PURCHASE_FORM, JSON.stringify(this.formModel));
      this.$router.push({
        name: "logistic.receivingitems.goodreceipt.importcost",
        query: {
          id: this.goodReceiptId,
          status: this.formModel.status,
        }
      });
    },
    async handleApprove(): Promise<void> {
      try {
        this.loading.approve = true;
        const payload = this.createPayloadUpdate();
        await receiveItemsService.approveReceiveItem(this.goodReceiptId, payload);
        this.showSuccessMessage("notif_approve_success");
        this.getDetailReceivingItem(this.goodReceiptId);
      } catch (error) {
        this.showErrorMessage("notif_approve_fail");
      } finally {
        this.loading.approve = false;
      }
    },
    onChangeDiscount(value: number, record: Row): void {
      // get data index based on numbering
      const { no } = record;
      // hitung ulang base amount kalau discount berubah
      const final = new Decimal(record.grossAmount || 0).minus(value || 0).toNumber();
      this.formModel.productLines[no - 1].baseAmount = final;
      this.calcTaxAmount(record);
      this.calcTotal(record);
    },
    onChangeUnitPrice(value: number, record: Row): void {
      // get data index based on numbering
      const { no } = record;
      // hitung ulang gross amount kalau unit price ganti
      const final = new Decimal(value || 0).times(record.qtyReceived).toNumber();
      this.formModel.productLines[no - 1].grossAmount = final;
      this.calcBaseAmount(record);
      this.calcTaxAmount(record);
      this.calcTotal(record);
    },
    calcBaseAmount(record: Row): void {
      const baseAmount = new Decimal(record.grossAmount || 0).minus(record.discount.value || 0);
      record.baseAmount = baseAmount.toNumber();
    },
  }
});
