var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-card",
    { attrs: { title: _vm.$t("lbl_good_receipt") } },
    [
      _c("span", { attrs: { slot: "extra" }, slot: "extra" }, [
        _vm._v(_vm._s(_vm.formModel.status || "-"))
      ]),
      _c(
        "a-card-grid",
        { staticStyle: { width: "100%" }, attrs: { hoverable: false } },
        [
          _c(
            "a-form-model",
            _vm._b(
              {
                ref: "grform",
                attrs: {
                  model: _vm.formModel,
                  "label-align": "left",
                  rules: _vm.rules
                }
              },
              "a-form-model",
              _vm.formWrapper,
              false
            ),
            [
              _c(
                "a-row",
                { attrs: { gutter: [16, 16] } },
                [
                  _c(
                    "a-col",
                    { attrs: { sm: 24, md: 12 } },
                    [
                      _c(
                        "a-form-model-item",
                        { attrs: { label: _vm.$t("lbl_branch") } },
                        [
                          _c("span", [
                            _vm._v(
                              _vm._s(_vm.formModel.branchName || "-") + " "
                            )
                          ])
                        ]
                      ),
                      _c(
                        "a-form-model-item",
                        { attrs: { label: _vm.$t("lbl_supplier_name") } },
                        [
                          _c("span", { staticClass: "ant-form-text" }, [
                            _vm._v(
                              " " +
                                _vm._s(_vm.formModel.supplierName || "-") +
                                " "
                            )
                          ])
                        ]
                      ),
                      _c(
                        "a-form-model-item",
                        { attrs: { label: _vm.$t("lbl_po_number") } },
                        [
                          _c("span", { staticClass: "ant-form-text" }, [
                            _vm._v(
                              " " + _vm._s(_vm.formModel.poNumber || "-") + " "
                            )
                          ])
                        ]
                      ),
                      _c(
                        "a-form-model-item",
                        { attrs: { label: _vm.$t("lbl_receive_date") } },
                        [
                          _c("span", { staticClass: "ant-form-text" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm._f("date")(_vm.formModel.receiveDate)
                                ) +
                                " "
                            )
                          ])
                        ]
                      ),
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: _vm.$t("lbl_good_receipt_checklist_number")
                          }
                        },
                        [
                          _c("span", { staticClass: "ant-form-text" }, [
                            _vm._v(
                              " " +
                                _vm._s(_vm.formModel.grChecklistNumber || "-") +
                                " "
                            )
                          ])
                        ]
                      ),
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: _vm.$t("lbl_term_of_payment"),
                            prop: "top"
                          }
                        },
                        [
                          _c("CSelectTermOfPayment", {
                            attrs: { disabled: _vm.isUnbilled },
                            model: {
                              value: _vm.formModel.top,
                              callback: function($$v) {
                                _vm.$set(_vm.formModel, "top", $$v)
                              },
                              expression: "formModel.top"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { sm: 24, md: 12 } },
                    [
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: _vm.$t("lbl_tax_calculation"),
                            prop: "taxCalculation"
                          }
                        },
                        [
                          _c("CSelectTaxCalculation", {
                            attrs: { disabled: _vm.isUnbilled },
                            on: { "on-select": _vm.onSelectTaxCalculation },
                            model: {
                              value: _vm.formModel.taxCalculation,
                              callback: function($$v) {
                                _vm.$set(_vm.formModel, "taxCalculation", $$v)
                              },
                              expression: "formModel.taxCalculation"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: _vm.$t("lbl_currency"),
                            prop: "currencyId"
                          }
                        },
                        [
                          _c("CSelectCurrency", {
                            attrs: {
                              "prop-default": _vm.isSubmitted,
                              disabled: _vm.isUnbilled
                            },
                            on: { "on-select": _vm.onSelectCurrency },
                            model: {
                              value: _vm.formModel.currencyId,
                              callback: function($$v) {
                                _vm.$set(_vm.formModel, "currencyId", $$v)
                              },
                              expression: "formModel.currencyId"
                            }
                          })
                        ],
                        1
                      ),
                      _vm.formModel.currency && _vm.formModel.currency !== "IDR"
                        ? _c(
                            "a-form-model-item",
                            {
                              attrs: {
                                label: _vm.$t("lbl_currency_rates"),
                                prop: "currencyRates"
                              }
                            },
                            [
                              _c("a-input-number", {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  formatter: _vm.formatterNumber,
                                  parser: _vm.reverseFormatNumber,
                                  precision: _vm.DECIMAL_PLACES_QTY,
                                  min: 0,
                                  placeholder: _vm.$t("lbl_type_here"),
                                  disabled: _vm.isUnbilled
                                },
                                model: {
                                  value: _vm.formModel.currencyRates,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.formModel,
                                      "currencyRates",
                                      _vm._n($$v)
                                    )
                                  },
                                  expression: "formModel.currencyRates"
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "a-form-model-item",
                        { attrs: { label: _vm.$t("lbl_supplier_do_number") } },
                        [
                          _c("span", { staticClass: "ant-form-text" }, [
                            _vm._v(
                              " " +
                                _vm._s(_vm.formModel.supplierDoNumber || "-") +
                                " "
                            )
                          ])
                        ]
                      ),
                      _c(
                        "a-form-model-item",
                        {
                          attrs: { label: _vm.$t("lbl_supplier_bill_address") }
                        },
                        [
                          _c("span", { staticClass: "ant-form-text" }, [
                            _vm._v(
                              " " +
                                _vm._s(_vm.formModel.supplierBillTo || "-") +
                                " "
                            )
                          ])
                        ]
                      ),
                      _c(
                        "a-form-model-item",
                        { attrs: { label: _vm.$t("lbl_supplier_import") } },
                        [
                          _c("a-checkbox", {
                            attrs: { disabled: "" },
                            model: {
                              value: _vm.formModel.supplierImport,
                              callback: function($$v) {
                                _vm.$set(_vm.formModel, "supplierImport", $$v)
                              },
                              expression: "formModel.supplierImport"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        { attrs: { label: _vm.$t("lbl_description") } },
                        [
                          !_vm.isUnbilled
                            ? _c("a-textarea", {
                                attrs: {
                                  placeholder: _vm.$t("lbl_type_here"),
                                  rows: { minRows: 1, maxRows: 4 },
                                  "allow-clear": ""
                                },
                                model: {
                                  value: _vm.formModel.description,
                                  callback: function($$v) {
                                    _vm.$set(_vm.formModel, "description", $$v)
                                  },
                                  expression: "formModel.description"
                                }
                              })
                            : _c("span", { staticClass: "ant-form-text" }, [
                                _vm._v(_vm._s(_vm.formModel.description || "-"))
                              ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "a-card-grid",
        { staticStyle: { width: "100%" }, attrs: { hoverable: false } },
        [
          _c("h4", { staticClass: "ant-form-text" }, [
            _c("u", [_vm._v(_vm._s(_vm.$t("lbl_product_details")))])
          ]),
          _c(
            "a-table",
            {
              staticStyle: { "margin-bottom": "1rem" },
              attrs: {
                "data-source": _vm.formModel.productLines,
                scroll: { x: "calc(1900px + 50%)", y: 520 },
                loading: _vm.loading.detail,
                pagination: {
                  showTotal: function(total) {
                    return _vm.$t("lbl_total_items", { total: total })
                  }
                }
              }
            },
            [
              _c(
                "a-table-column",
                {
                  key: "no",
                  attrs: { width: "75px" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(text, record) {
                        return _c("span", {}, [_vm._v(_vm._s(record.no))])
                      }
                    }
                  ])
                },
                [
                  _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                    _vm._v(" " + _vm._s(_vm.$t("lbl_number_short")) + " ")
                  ])
                ]
              ),
              _c(
                "a-table-column",
                { key: "productCode", attrs: { "data-index": "productCode" } },
                [
                  _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                    _vm._v(" " + _vm._s(_vm.$t("lbl_product_code")) + " ")
                  ])
                ]
              ),
              _c(
                "a-table-column",
                { key: "productName", attrs: { "data-index": "productName" } },
                [
                  _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                    _vm._v(" " + _vm._s(_vm.$t("lbl_product_name")) + " ")
                  ])
                ]
              ),
              _c(
                "a-table-column",
                {
                  key: "qtyPo",
                  attrs: { "data-index": "qtyPo", width: "100px" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(text, record) {
                        return [
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm._f("toDecimalQty")(
                                  record.qtyPo !== null ? record.qtyPo : 0
                                )
                              )
                            )
                          ])
                        ]
                      }
                    }
                  ])
                },
                [
                  _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                    _vm._v(" " + _vm._s(_vm.$t("lbl_qty_po")) + " ")
                  ])
                ]
              ),
              _c(
                "a-table-column",
                {
                  key: "productUom",
                  attrs: { "data-index": "productUom", width: "100px" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(productUom) {
                        return [_vm._v(" " + _vm._s(productUom || "-") + " ")]
                      }
                    }
                  ])
                },
                [
                  _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                    _vm._v(" " + _vm._s(_vm.$t("lbl_uom")) + " ")
                  ])
                ]
              ),
              _c(
                "a-table-column",
                {
                  key: "qtyReceived",
                  attrs: { "data-index": "qtyReceived" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(text, record) {
                        return [
                          _c("span", [
                            _vm._v(
                              _vm._s(_vm._f("toDecimalQty")(record.qtyReceived))
                            )
                          ])
                        ]
                      }
                    }
                  ])
                },
                [
                  _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                    _vm._v(" " + _vm._s(_vm.$t("lbl_qty_received")) + " ")
                  ])
                ]
              ),
              _c(
                "a-table-column",
                {
                  key: "unitPrice",
                  attrs: { "data-index": "unitPrice" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(text, record) {
                        return [
                          !_vm.isUnbilled
                            ? _c("a-input-number", {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  formatter: _vm.formatterNumber,
                                  parser: _vm.reverseFormatNumber,
                                  precision: _vm.DECIMAL_PLACES_CURRENCY,
                                  min: 0
                                },
                                on: {
                                  change: function(value) {
                                    return _vm.onChangeUnitPrice(value, record)
                                  }
                                },
                                model: {
                                  value: record.unitPrice,
                                  callback: function($$v) {
                                    _vm.$set(record, "unitPrice", _vm._n($$v))
                                  },
                                  expression: "record.unitPrice"
                                }
                              })
                            : _c("span", [
                                _vm._v(
                                  _vm._s(_vm._f("currency")(record.unitPrice))
                                )
                              ])
                        ]
                      }
                    }
                  ])
                },
                [
                  _c(
                    "span",
                    {
                      staticClass: "is-required",
                      attrs: { slot: "title" },
                      slot: "title"
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("lbl_unit_price")) + " ")]
                  )
                ]
              ),
              _c(
                "a-table-column",
                {
                  key: "grossAmount",
                  attrs: { "data-index": "grossAmount" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(text, record) {
                        return [
                          _c("span", [
                            _vm._v(
                              _vm._s(_vm._f("currency")(record.grossAmount))
                            )
                          ])
                        ]
                      }
                    }
                  ])
                },
                [
                  _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                    _vm._v(" " + _vm._s(_vm.$t("lbl_gross_amount")) + " ")
                  ])
                ]
              ),
              _c(
                "a-table-column",
                {
                  key: "discount",
                  attrs: { "data-index": "discount.value" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(text, record) {
                        return [
                          !_vm.isUnbilled
                            ? _c("a-input-number", {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  formatter: _vm.formatterNumber,
                                  parser: _vm.reverseFormatNumber,
                                  precision: _vm.DECIMAL_PLACES_CURRENCY,
                                  placeholder: _vm.$t("lbl_type_here"),
                                  min: 0
                                },
                                on: {
                                  change: function(value) {
                                    return _vm.onChangeDiscount(value, record)
                                  }
                                },
                                model: {
                                  value: record.discount.value,
                                  callback: function($$v) {
                                    _vm.$set(
                                      record.discount,
                                      "value",
                                      _vm._n($$v)
                                    )
                                  },
                                  expression: "record.discount.value"
                                }
                              })
                            : _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("currency")(record.discount.value)
                                  )
                                )
                              ])
                        ]
                      }
                    }
                  ])
                },
                [
                  _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                    _vm._v(" " + _vm._s(_vm.$t("lbl_discount")) + " ")
                  ])
                ]
              ),
              _c(
                "a-table-column",
                {
                  key: "tax",
                  attrs: { "data-index": "tax" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(text, record) {
                        return [
                          _c("CSelectTaxCode", {
                            attrs: {
                              "tax-type": _vm.TAX_TYPE.VAT_IN,
                              disabled:
                                _vm.isUnbilled ||
                                _vm.isTaxCalculationNone ||
                                !_vm.formModel.taxCalculation
                            },
                            on: {
                              "on-select": function(e) {
                                return _vm.onSelectTax(e, record)
                              }
                            },
                            model: {
                              value: record.taxId,
                              callback: function($$v) {
                                _vm.$set(record, "taxId", $$v)
                              },
                              expression: "record.taxId"
                            }
                          })
                        ]
                      }
                    }
                  ])
                },
                [
                  _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                    _vm._v(" " + _vm._s(_vm.$t("lbl_tax")) + " ")
                  ])
                ]
              ),
              _c(
                "a-table-column",
                {
                  key: "baseAmount",
                  attrs: { "data-index": "baseAmount" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(text, record) {
                        return [
                          _c("span", [
                            _vm._v(
                              _vm._s(_vm._f("currency")(record.baseAmount))
                            )
                          ])
                        ]
                      }
                    }
                  ])
                },
                [
                  _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                    _vm._v(" " + _vm._s(_vm.$t("lbl_base_amount")) + " ")
                  ])
                ]
              ),
              _c(
                "a-table-column",
                {
                  key: "taxAmount",
                  attrs: { "data-index": "taxAmount" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(text, record) {
                        return [
                          _c("span", [
                            _vm._v(_vm._s(_vm._f("currency")(record.taxAmount)))
                          ])
                        ]
                      }
                    }
                  ])
                },
                [
                  _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                    _vm._v(" " + _vm._s(_vm.$t("lbl_tax_amount")) + " ")
                  ])
                ]
              ),
              _c(
                "a-table-column",
                {
                  key: "total",
                  attrs: { "data-index": "total" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(text, record) {
                        return [
                          _c("span", [
                            _vm._v(_vm._s(_vm._f("currency")(record.total)))
                          ])
                        ]
                      }
                    }
                  ])
                },
                [
                  _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                    _vm._v(" " + _vm._s(_vm.$t("lbl_total")) + " ")
                  ])
                ]
              ),
              _c(
                "a-table-column",
                {
                  key: "locationReceived",
                  attrs: { "data-index": "locationReceived" }
                },
                [
                  _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                    _vm._v(" " + _vm._s(_vm.$t("lbl_location_received")) + " ")
                  ])
                ]
              ),
              _c(
                "a-table-column",
                {
                  key: "trackAsAsset",
                  attrs: { "data-index": "trackAsAsset", align: "center" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(text, record) {
                        return [
                          _c("a-checkbox", {
                            attrs: { disabled: "" },
                            model: {
                              value: record.trackAsAsset,
                              callback: function($$v) {
                                _vm.$set(record, "trackAsAsset", $$v)
                              },
                              expression: "record.trackAsAsset"
                            }
                          })
                        ]
                      }
                    }
                  ])
                },
                [
                  _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                    _vm._v(" " + _vm._s(_vm.$t("lbl_track_as_asset")) + " ")
                  ])
                ]
              ),
              _c(
                "a-table-column",
                {
                  key: "batchNumber",
                  attrs: { "data-index": "batchNumber", align: "center" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(text, record) {
                        return [
                          record.batchNumber
                            ? _c("QrCode", {
                                attrs: {
                                  value: record.batchNumber,
                                  quality: 1,
                                  scale: 2,
                                  size: 80,
                                  "white-margin": false
                                }
                              })
                            : _c("span", { staticClass: "ant-form-text" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.$t("lbl_not_available")) +
                                    " "
                                )
                              ])
                        ]
                      }
                    }
                  ])
                },
                [
                  _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                    _vm._v(" " + _vm._s(_vm.$t("lbl_qrcode")) + " ")
                  ])
                ]
              )
            ],
            1
          ),
          _c("div", { staticClass: "d-flex justify-content-end" }, [
            _c("table", { staticClass: "w-100" }, [
              _c("tr", [
                _c("td", { staticClass: "text-right" }, [
                  _vm._v(" " + _vm._s(_vm.$t("lbl_total_qty_received")) + " : ")
                ]),
                _c("td", { attrs: { width: "10px" } }, [
                  _vm._v(
                    " " +
                      _vm._s(_vm._f("toDecimalQty")(_vm.totalQtyReceived)) +
                      " "
                  )
                ])
              ]),
              _c("tr", [
                _c("td", { staticClass: "text-right" }, [
                  _vm._v(" " + _vm._s(_vm.$t("lbl_total_price")) + " : ")
                ]),
                _c("td", [
                  _vm._v(" " + _vm._s(_vm._f("currency")(_vm.totalPrice)) + " ")
                ])
              ]),
              _c("tr", [
                _c("td", { staticClass: "text-right" }, [
                  _vm._v(" " + _vm._s(_vm.$t("lbl_total_gross_amount")) + " : ")
                ]),
                _c("td", [
                  _vm._v(
                    " " + _vm._s(_vm._f("currency")(_vm.totalGrossAmount)) + " "
                  )
                ])
              ]),
              _c("tr", [
                _c("td", { staticClass: "text-right" }, [
                  _vm._v(" " + _vm._s(_vm.$t("lbl_total_tax")) + " : ")
                ]),
                _c("td", [
                  _vm._v(" " + _vm._s(_vm._f("currency")(_vm.totalTax)) + " ")
                ])
              ]),
              _c("tr", [
                _c("td", { staticClass: "text-right" }, [
                  _vm._v(" " + _vm._s(_vm.$t("lbl_total_discount")) + " : ")
                ]),
                _c("td", [
                  _vm._v(
                    " " + _vm._s(_vm._f("currency")(_vm.totalDiscount)) + " "
                  )
                ])
              ]),
              _c("tr", [
                _c("td", { staticClass: "text-right" }, [
                  _vm._v(" " + _vm._s(_vm.$t("lbl_grand_total")) + " : ")
                ]),
                _c("td", [
                  _vm._v(" " + _vm._s(_vm._f("currency")(_vm.grandTotal)) + " ")
                ])
              ])
            ])
          ])
        ],
        1
      ),
      _c(
        "a-card-grid",
        { staticStyle: { width: "100%" }, attrs: { hoverable: false } },
        [
          _c(
            "div",
            { staticClass: "d-flex justify-content-end" },
            [
              _c(
                "a-space",
                [
                  _c("a-button", { on: { click: _vm.handleBack } }, [
                    _vm._v(" " + _vm._s(_vm.$t("lbl_back")) + " ")
                  ]),
                  _vm.isWaitForApproval && _vm.hasPrivilegeApprove
                    ? _c(
                        "a-button",
                        {
                          attrs: {
                            type: "primary",
                            icon: "check",
                            loading: _vm.loading.approve
                          },
                          on: { click: _vm.handleApprove }
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("lbl_approve")) + " ")]
                      )
                    : _vm._e(),
                  _vm.hasPrivilegeCreate || _vm.hasPrivilegeUpdate
                    ? [
                        _vm.formModel.supplierImport
                          ? _c(
                              "a-button",
                              {
                                attrs: {
                                  type: "primary",
                                  icon: "account-book",
                                  disabled:
                                    !_vm.formModel.currencyId ||
                                    !_vm.formModel.currencyRates
                                },
                                on: { click: _vm.showImportCost }
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.$t("lbl_form_import_cost")) +
                                    " "
                                )
                              ]
                            )
                          : _c(
                              "a-button",
                              {
                                attrs: {
                                  type: "primary",
                                  loading: _vm.loading.submit,
                                  disabled: _vm.isUnbilled,
                                  icon: "save"
                                },
                                on: { click: _vm.validateForm }
                              },
                              [_vm._v(" " + _vm._s(_vm.$t("lbl_submit")) + " ")]
                            )
                      ]
                    : _vm._e()
                ],
                2
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }