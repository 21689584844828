import { Api } from "@/models/class/api.class";
import { ResponseListAccountingCurrencyConversion } from "@interface/accounting-currency-conversion.interface";
import { ErrorResponseModel, RequestQueryParamsModel } from "@/models/interface/http.interface";
import { HttpClient } from "@/services/http.service";
import { AxiosError } from "axios";

export class AccountingCurrencyConversionServices extends HttpClient {
  constructor() {
    super();
  }

  conversionList(params: RequestQueryParamsModel): Promise<ResponseListAccountingCurrencyConversion> {
    return this.get<ResponseListAccountingCurrencyConversion>(Api.CurrencyConversion, { params })
    .then(this.success)
    .catch((error: AxiosError<ErrorResponseModel>) => {
      throw this.error(error);
    });
  }
}

export const accountingCurrencyConversionService = new AccountingCurrencyConversionServices();
